.pulsing {
  animation: pulse-animation 2s infinite
    theme("transitionTimingFunction.out-quart");
  box-shadow: 0 0 2px 8px currentColor;
}

@keyframes pulse-animation {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
